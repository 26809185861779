/**
 * Imports with side effects.
 */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

/**
 * External imports.
 */
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

/**
 * Internal imports.
 */
import TopOfSite from './Components/TopOfSite';
import DynamicText from './Components/Text';

function App() {
  return (
    <div className="body">
      <Container className="min-vh-100 d-flex flex-column">
        <TopOfSite />
        <Row className="flex-fill text-center align-items-center">
          <Col className="text-light">
            <DynamicText />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;