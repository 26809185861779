import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import HamburgerMenu from './HamburgerMenu';

function TopOfSite() {

    return (
        <Navbar bg="transparent" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="./">
                    <img
                        alt="logotyp"
                        src={process.env.PUBLIC_URL + '/logo.svg'}
                        width="150"
                    />
                </Navbar.Brand>
                <HamburgerMenu />
            </Container>
        </Navbar>
    );
}

export default TopOfSite;
