import {useRef, useEffect} from 'react';
import Typed from 'typed.js';

function DynamicText() {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: prefixWords,
      typeSpeed: 100,
      backSpeed: 100,
      shuffle: true,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <h1>Eterpost,</h1>
      <h2>inte <span ref={el}/></h2>
    </div>
  );
}

export default DynamicText;

const prefixWords = [
  "^700 myndighetspost.",
  "^700 snigelpost.",
  "^700 E-post.",
  "^700 brevpost.",
  "^700 cykelpost.",
  "^700 luftpost.",
  "^700 kundpost.",
  "^700 arbetspost.",
  "^700 skolpost.",
  "^700 järnvägspost.",
  "^700 morgonpost.",
  "^700 kvällspost.",
  "^700 flygpost.",
  "^700 paketpost.",
  "^700 fartygspost.",
  "^700 nyhetspost.",
  "^700 expresspost.",
  "^700 tullpost.",
  "^700 tidningspost.",
  "^700 brevbärarpost.",
  "^700 fjärrpost.",
  "^700 duvpost.",
  "^700 grannpost.",
  "^700 telegrafipost.",
  "^700 flyttpost.",
  "^700 kvällstidningspost.",
  "^700 tjänstepost.",
  "^700 kontorspost.",
  "^700 stadsdelspost.", 
  "^700 hotellpost.",
  "^700 föreningspost."
];