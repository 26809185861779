import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Sling as Hamburger } from 'hamburger-react'

function HamburgerMenu() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown 
            show={isOpen} 
            onToggle={(boolean) => {
            setIsOpen(boolean);
            }}
            align="end"
        >
            <Hamburger
                rounded
                size={35}
                duration={0.9}
                color='#ff0073'
                toggled={isOpen}
                onToggle={(boolean2) => {
                    setIsOpen(boolean2);
                }}
            />
            <Dropdown.Menu>
                <Dropdown.Item href="./L1/L1.html"><center>Länk 1</center></Dropdown.Item>
                <Dropdown.Item href="./L2/L2.html"><center>Länk 2</center></Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default HamburgerMenu;
